import React from "react";
import { Grid } from "@mui/material";
import AppSlider from "../../AppSlider";
import { BRAND_1_LIST, BRAND_2_LIST } from "../../../data/StaticList";

function Brand() {
  return (
    <Grid
      container
      sx={{
        position: "relative",
        minHeight: { xs: "30vh", md: "50vh" },
        justifyContent: "space-around",
        flexDirection: "column",
        padding: { xs: "48px 0", md: "60px 0" },
        maxWidth: "1440px",
        margin: "auto",
      }}
    >
      <AppSlider
        list={BRAND_1_LIST}
        directionLTR
        width={240}
        numbers={4}
        title="Our Financial Partners"
        titleMargin={"25px auto"}
      />
      <AppSlider list={BRAND_2_LIST} width={240} numbers={4} />
    </Grid>
  );
}

export default Brand;
